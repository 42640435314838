import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import { getFormData } from 'helpers/form-data.helper';
import {
  ActivePhotoFilterRequestModel,
  ActivePhotoFilterResponseModel,
  DeletePhotoFilterRequestModel,
  DeletePhotoFilterResponseModel,
  GetPhotoFilterRequestModel,
  GetPhotoFilterResponseModel,
  InactivePhotoFilterRequestModel,
  InactivePhotoFilterResponseModel,
  ListPhotoFilterRequestModel,
  ListPhotoFilterResponseModel,
  SavePhotoFilterRequestModel,
  SavePhotoFilterResponseModel,
} from 'models/setting/photo-filter/photo-filter.model';

export function getPhotoFilter(payload: GetPhotoFilterRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetPhotoFilterResponseModel,
      GetPhotoFilterResponseModel
    >(`${PREFIX_API.GENERAL}/filters/${payload?.id}`);
  }
}

export function listPhotoFilter(payload: ListPhotoFilterRequestModel) {
  return authorizedRequest.get<
    ListPhotoFilterResponseModel,
    ListPhotoFilterResponseModel
  >(`${PREFIX_API.GENERAL}/filters?${preprocessGetQuery(payload)}`);
}

export function createPhotoFilter(payload: SavePhotoFilterRequestModel) {
  const formDataPayload = getFormData(payload);

  return authorizedRequest.post<
    SavePhotoFilterResponseModel,
    SavePhotoFilterResponseModel
  >(`${PREFIX_API.GENERAL}/filters`, formDataPayload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function updatePhotoFilter(payload: SavePhotoFilterRequestModel) {
  const formDataPayload = getFormData(payload);

  if (payload?.id) {
    return authorizedRequest.put<
      SavePhotoFilterResponseModel,
      SavePhotoFilterResponseModel
    >(`${PREFIX_API.GENERAL}/filters/${payload?.id}`, formDataPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export function deletePhotoFilter(payload: DeletePhotoFilterRequestModel) {
  return authorizedRequest.delete<
    DeletePhotoFilterResponseModel,
    DeletePhotoFilterResponseModel
  >(`${PREFIX_API.GENERAL}/filters/${payload?.id}`);
}

export function inactivePhotoFilter(payload: InactivePhotoFilterRequestModel) {
  return authorizedRequest.put<
    InactivePhotoFilterResponseModel,
    InactivePhotoFilterResponseModel
  >(`${PREFIX_API.GENERAL}/filters/${payload?.id}/inactive`);
}

export function activePhotoFilter(payload: ActivePhotoFilterRequestModel) {
  return authorizedRequest.put<
    ActivePhotoFilterResponseModel,
    ActivePhotoFilterResponseModel
  >(`${PREFIX_API.GENERAL}/filters/${payload?.id}/active`);
}
