import { combineReducers } from '@reduxjs/toolkit';
import { photoFilterSlice } from './photo-filter/photo-filter.slice';
import { photoFilterTemplateSlice } from './photo-filter/photo-filter-template.slice';

const settingReducer = combineReducers({
  photoFilter: photoFilterSlice.reducer,
  photoFilterTemplate: photoFilterTemplateSlice.reducer,
});

export default settingReducer;
