import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActivePhotoFilterTemplateRequestModel,
  ActivePhotoFilterTemplateResponseModel,
  DeletePhotoFilterTemplateRequestModel,
  DeletePhotoFilterTemplateResponseModel,
  GetPhotoFilterTemplateRequestModel,
  GetPhotoFilterTemplateResponseModel,
  InactivePhotoFilterTemplateRequestModel,
  InactivePhotoFilterTemplateResponseModel,
  ListPhotoFilterTemplateRequestModel,
  ListPhotoFilterTemplateResponseModel,
  SavePhotoFilterTemplateRequestModel,
  SavePhotoFilterTemplateResponseModel,
} from 'models/setting/photo-filter/photo-filter-template.model';

export function getPhotoFilterTemplate(
  payload: GetPhotoFilterTemplateRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetPhotoFilterTemplateResponseModel,
      GetPhotoFilterTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/filter-lists/${payload?.id}`);
  }
}

export function listPhotoFilterTemplate(
  payload: ListPhotoFilterTemplateRequestModel,
) {
  return authorizedRequest.get<
    ListPhotoFilterTemplateResponseModel,
    ListPhotoFilterTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/filter-lists?${preprocessGetQuery(payload)}`);
}

export function createPhotoFilterTemplate(
  payload: SavePhotoFilterTemplateRequestModel,
) {
  return authorizedRequest.post<
    SavePhotoFilterTemplateResponseModel,
    SavePhotoFilterTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/filter-lists`, payload);
}

export function updatePhotoFilterTemplate(
  payload: SavePhotoFilterTemplateRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.put<
      SavePhotoFilterTemplateResponseModel,
      SavePhotoFilterTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/filter-lists/${payload?.id}`, payload);
  }
}

export function deletePhotoFilterTemplate(
  payload: DeletePhotoFilterTemplateRequestModel,
) {
  return authorizedRequest.delete<
    DeletePhotoFilterTemplateResponseModel,
    DeletePhotoFilterTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/filter-lists/${payload?.id}`);
}

export function inactivePhotoFilterTemplate(
  payload: InactivePhotoFilterTemplateRequestModel,
) {
  return authorizedRequest.put<
    InactivePhotoFilterTemplateResponseModel,
    InactivePhotoFilterTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/filter-lists/${payload?.id}/inactive`);
}

export function activePhotoFilterTemplate(
  payload: ActivePhotoFilterTemplateRequestModel,
) {
  return authorizedRequest.put<
    ActivePhotoFilterTemplateResponseModel,
    ActivePhotoFilterTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/filter-lists/${payload?.id}/active`);
}
