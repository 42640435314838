import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activePhotoFilterTemplate,
  createPhotoFilterTemplate,
  deletePhotoFilterTemplate,
  getPhotoFilterTemplate,
  inactivePhotoFilterTemplate,
  listPhotoFilterTemplate,
  updatePhotoFilterTemplate,
} from 'api/setting/photo-filter/photo-filter-template.api';
import {
  ActivePhotoFilterTemplateRequestModel,
  DeletePhotoFilterTemplateRequestModel,
  GetPhotoFilterTemplateRequestModel,
  InactivePhotoFilterTemplateRequestModel,
  ListPhotoFilterTemplateRequestModel,
  SavePhotoFilterTemplateRequestModel,
} from 'models/setting/photo-filter/photo-filter-template.model';

export const getPhotoFilterTemplateAction = createAsyncThunk(
  'GET_PHOTO_FILTER_TEMPLATE_REQUEST',
  async (payload: GetPhotoFilterTemplateRequestModel) =>
    getPhotoFilterTemplate(payload),
);

export const listPhotoFilterTemplateAction = createAsyncThunk(
  'LIST_PHOTO_FILTER_TEMPLATE_REQUEST',
  async (payload: ListPhotoFilterTemplateRequestModel) =>
    listPhotoFilterTemplate(payload),
);

export const createPhotoFilterTemplateAction = createAsyncThunk(
  'CREATE_PHOTO_FILTER_TEMPLATE_REQUEST',
  async (payload: SavePhotoFilterTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await createPhotoFilterTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updatePhotoFilterTemplateAction = createAsyncThunk(
  'UPDATE_PHOTO_FILTER_TEMPLATE_REQUEST',
  async (payload: SavePhotoFilterTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await updatePhotoFilterTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deletePhotoFilterTemplateAction = createAsyncThunk(
  'DELETE_PHOTO_FILTER_TEMPLATE_REQUEST',
  async (
    payload: DeletePhotoFilterTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await deletePhotoFilterTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactivePhotoFilterTemplateAction = createAsyncThunk(
  'INACTIVE_PHOTO_FILTER_TEMPLATE_REQUEST',
  async (
    payload: InactivePhotoFilterTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await inactivePhotoFilterTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activePhotoFilterTemplateAction = createAsyncThunk(
  'ACTIVE_PHOTO_FILTER_TEMPLATE_REQUEST',
  async (
    payload: ActivePhotoFilterTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await activePhotoFilterTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
