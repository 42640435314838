import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activePhotoFilter,
  createPhotoFilter,
  deletePhotoFilter,
  getPhotoFilter,
  inactivePhotoFilter,
  listPhotoFilter,
  updatePhotoFilter,
} from 'api/setting/photo-filter/photo-filter.api';
import {
  ActivePhotoFilterRequestModel,
  DeletePhotoFilterRequestModel,
  GetPhotoFilterRequestModel,
  InactivePhotoFilterRequestModel,
  ListPhotoFilterRequestModel,
  SavePhotoFilterRequestModel,
} from 'models/setting/photo-filter/photo-filter.model';

export const getPhotoFilterAction = createAsyncThunk(
  'GET_PHOTO_FILTER_REQUEST',
  async (payload: GetPhotoFilterRequestModel) => getPhotoFilter(payload),
);

export const listPhotoFilterAction = createAsyncThunk(
  'LIST_PHOTO_FILTER_REQUEST',
  async (payload: ListPhotoFilterRequestModel) => listPhotoFilter(payload),
);

export const createPhotoFilterAction = createAsyncThunk(
  'CREATE_PHOTO_FILTER_REQUEST',
  async (payload: SavePhotoFilterRequestModel, { rejectWithValue }) => {
    try {
      return await createPhotoFilter(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updatePhotoFilterAction = createAsyncThunk(
  'UPDATE_PHOTO_FILTER_REQUEST',
  async (payload: SavePhotoFilterRequestModel, { rejectWithValue }) => {
    try {
      return await updatePhotoFilter(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deletePhotoFilterAction = createAsyncThunk(
  'DELETE_PHOTO_FILTER_REQUEST',
  async (payload: DeletePhotoFilterRequestModel, { rejectWithValue }) => {
    try {
      return await deletePhotoFilter(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactivePhotoFilterAction = createAsyncThunk(
  'INACTIVE_PHOTO_FILTER_REQUEST',
  async (payload: InactivePhotoFilterRequestModel, { rejectWithValue }) => {
    try {
      return await inactivePhotoFilter(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activePhotoFilterAction = createAsyncThunk(
  'ACTIVE_PHOTO_FILTER_REQUEST',
  async (payload: ActivePhotoFilterRequestModel, { rejectWithValue }) => {
    try {
      return await activePhotoFilter(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
