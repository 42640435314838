import React, { FC, memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Controller, useFormContext } from 'react-hook-form';
import FormField, { FormFieldProps } from 'components/form/FormField';
import { keepValueOnlyNumber } from 'helpers/math.helper';
import Input, { Props as InputProps } from '../input/Input';
import FormFieldLabel, { FormFieldLabelProps } from './FormFieldLabel';
import './hook-form.css';

export type HookFormInputProps = InputProps & {
  name: string;
  showErrorLabel?: boolean;
  isPasswordField?: boolean;
  required?: boolean;
  FormFieldProps?: FormFieldProps;

  formLabel?: FormFieldLabelProps['content'];
  LabelFieldProps?: FormFieldLabelProps;
};

const HookFormInput: FC<HookFormInputProps> = ({
  name = '',
  showErrorLabel = true,
  isPasswordField,
  required,
  FormFieldProps,

  formLabel,
  LabelFieldProps,

  ...rest
}) => {
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const [isPasswordType, setIsPasswordType] = useState<boolean>(true);

  const isError = !!errors?.[name] || rest.isError;

  // debounce onchange
  // const handleChange = useCallback(
  //   (e: React.ChangeEvent<any>, field: FieldProps['field']) => {
  //     field.onChange(name)(e);
  //     onChange?.(e);
  //   },
  //   [onChange, name],
  // );

  // const debounceHandleChange = useCallback(
  //   debounce(handleChange, 100, {
  //     leading: false,
  //     trailing: true,
  //   }),
  //   [handleChange],
  // );

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormField {...FormFieldProps}>
            <FormFieldLabel
              content={formLabel}
              required={required}
              {...LabelFieldProps}
            />
            <Input
              {...field}
              {...rest}
              title={`${
                (isError ? errors?.[name]?.message : rest?.title) || ''
              }`}
              isError={isError || rest?.isError}
              onChange={(e, data) => {
                field?.onChange?.(e, data);
                rest?.onChange?.(e, data);
              }}
              {...(rest?.isMoneyMask && {
                onChange: (e, data) => {
                  setValue?.(name, keepValueOnlyNumber(e.target.value) || '');
                  rest?.onChange?.(e, data);
                },
              })}
              {...(isPasswordField && {
                type: !isPasswordType ? 'text' : 'password',
                action: {
                  type: 'button',
                  icon: !isPasswordType ? 'eye' : 'eye slash',
                  onClick: () => setIsPasswordType((t) => !t),
                  ...rest?.action,
                },
              })}
            />
          </FormField>
        )}
      />
      {showErrorLabel && isError && (
        <p className="hf-error-text">{`${errors?.[name]?.message}`}</p>
      )}
    </>
  );
};

export default memo(HookFormInput, isEqual);
