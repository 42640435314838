import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { PhotoFilterStateModel } from 'models/setting/photo-filter/photo-filter.model';
import {
  activePhotoFilterAction,
  createPhotoFilterAction,
  deletePhotoFilterAction,
  getPhotoFilterAction,
  inactivePhotoFilterAction,
  listPhotoFilterAction,
  updatePhotoFilterAction,
} from './photo-filter.action';

const initialState: PhotoFilterStateModel = {
  photoFilterListData: null,
  photoFilterDetail: null,
  triggerSavePhotoFilter: null,

  loadingList: false,
  loadingDetail: false,
};

export const photoFilterSlice = createSlice({
  name: 'photoFilter',
  initialState,
  reducers: {
    setPhotoFilterStateAction: (
      state,
      action: PayloadAction<Partial<PhotoFilterStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as PhotoFilterStateModel),
    resetPhotoFilterStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPhotoFilterAction.pending, (state) => ({
        ...state,
        photoFilterDetail: null,
        loadingDetail: true,
      }))
      .addCase(getPhotoFilterAction.fulfilled, (state, { payload }) => ({
        ...state,
        photoFilterDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getPhotoFilterAction.rejected, (state) => ({
        ...state,
        photoFilterDetail: null,
        loadingDetail: false,
      }))
      .addCase(listPhotoFilterAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listPhotoFilterAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        photoFilterListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listPhotoFilterAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createPhotoFilterAction.fulfilled, (state) => ({
        ...state,
        triggerSavePhotoFilter: { id: uuidv4() },
      }))
      .addCase(updatePhotoFilterAction.fulfilled, (state) => ({
        ...state,
        triggerSavePhotoFilter: { id: uuidv4() },
      }))

      .addCase(deletePhotoFilterAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deletePhotoFilterAction.fulfilled, (state) => ({
        ...state,
        triggerSavePhotoFilter: { id: uuidv4() },
      }))
      .addCase(deletePhotoFilterAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activePhotoFilterAction.fulfilled, (state) => ({
        ...state,
        triggerSavePhotoFilter: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactivePhotoFilterAction.fulfilled, (state) => ({
        ...state,
        triggerSavePhotoFilter: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setPhotoFilterStateAction, resetPhotoFilterStateAction } =
  photoFilterSlice.actions;
