import React, { FC, useMemo } from 'react';
import { I18nNamespace } from 'constants/i18n.const';
import { AppRouteConst } from 'constants/route.const';
import { usePermission } from 'hooks/usePermission';
import { useRouter } from 'hooks/useRouter';
import { useTranslation } from 'hooks/useTranslation';
import { forEach, size } from 'lodash';
import { BreadCrumbItemTypes } from 'models/layout.model';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const HeaderBreadcrumb: FC = () => {
  const { T } = useTranslation(I18nNamespace.COMMON);
  const { isMatchPath } = useRouter();
  const { getAllowingByPathname } = usePermission();
  const { canView: isCanViewUser } = getAllowingByPathname?.(
    AppRouteConst.USER,
  );

  const routes: BreadCrumbItemTypes[] = useMemo(() => {
    const routesOfProfile = [
      {
        path: AppRouteConst.PROFILE.DETAIL,
        name: T('page.profile'),
      },
      {
        path: AppRouteConst.PROFILE.CHANGE_PASSWORD,
        name: T('page.changePassword'),
      },
      {
        path: AppRouteConst.PROFILE.MY_PROFILE,
        name: T('page.profile'),
      },
    ];

    return [
      {
        path: AppRouteConst.HOME,
        name: T('page.dashboard'),
      },
      {
        path: AppRouteConst.DASHBOARD,
        name: T('page.dashboard'),
      },
      {
        path: AppRouteConst.TRANSACTION,
        name: T('page.transaction'),
      },
      {
        path: AppRouteConst.COMPANY,
        name: T('page.company'),
      },
      {
        path: AppRouteConst.STORE,
        name: T('page.store'),
      },
      {
        path: AppRouteConst.BOOTH,
        name: T('page.booth'),
      },
      {
        path: AppRouteConst.REPORT.LIST,
        name: T('page.report'),
        children: [
          {
            path: AppRouteConst.REPORT.REPORT_BY_SHOOTING_COUNT,
            name: T('page.reportByShootingCount'),
          },
          {
            path: AppRouteConst.REPORT.REPORT_SHOOTING_DURATION,
            name: T('page.reportShootingDuration'),
          },
          {
            path: AppRouteConst.REPORT.REPORT_ERROR_BOOTH,
            name: T('page.reportStatisticErrorBooth'),
          },
          {
            path: AppRouteConst.REPORT.REPORT_DEPOSIT_LOG,
            name: T('page.reportReportDepositLog'),
          },
        ],
      },
      {
        path: AppRouteConst.LAYOUT_TEMPLATE.LIST,
        name: T('page.layoutList'),
        children: [
          {
            path: AppRouteConst.LAYOUT_TEMPLATE.CREATE,
            name: T('page.createLayoutList'),
          },
          {
            path: AppRouteConst.LAYOUT_TEMPLATE.EDIT,
            name: T('page.editLayoutList'),
          },
        ],
      },
      {
        path: AppRouteConst.THEME.LIST,
        name: T('page.theme'),
        children: [
          {
            path: AppRouteConst.THEME.CREATE,
            name: T('page.createTheme'),
          },
          {
            path: AppRouteConst.THEME.EDIT,
            name: T('page.editTheme'),
          },
          {
            path: AppRouteConst.THEME_CATEGORY,
            name: T('page.themeCategory'),
          },
          {
            path: AppRouteConst.THEME_TEMPLATE.LIST,
            name: T('page.themeList'),
            children: [
              {
                path: AppRouteConst.THEME_TEMPLATE.CREATE,
                name: T('page.createThemeList'),
              },
              {
                path: AppRouteConst.THEME_TEMPLATE.EDIT,
                name: T('page.editThemeList'),
              },
            ],
          },
        ],
      },

      // sticker
      {
        path: AppRouteConst.STICKER.LIST,
        name: T('page.sticker'),
        children: [
          {
            path: AppRouteConst.STICKER.CREATE,
            name: T('page.createSticker'),
          },
          {
            path: AppRouteConst.STICKER.EDIT,
            name: T('page.editSticker'),
          },
          {
            path: AppRouteConst.STICKER_CATEGORY,
            name: T('page.stickerCategory'),
          },
          {
            path: AppRouteConst.STICKER_TEMPLATE.LIST,
            name: T('page.stickerList'),
            children: [
              {
                path: AppRouteConst.STICKER_TEMPLATE.CREATE,
                name: T('page.createStickerList'),
              },
              {
                path: AppRouteConst.STICKER_TEMPLATE.EDIT,
                name: T('page.editStickerList'),
              },
            ],
          },
        ],
      },

      {
        path: AppRouteConst.PROMOTION_CAMPAIGN,
        name: T('page.promotion'),
      },
      {
        path: AppRouteConst.VOUCHER.LIST,
        name: T('page.voucher'),
        children: [
          {
            path: AppRouteConst.VOUCHER.CREATE,
            name: T('page.createVoucher'),
          },
          {
            path: AppRouteConst.VOUCHER.EDIT,
            name: T('page.editTheme'),
          },
        ],
      },
      {
        path: AppRouteConst.LOCATION,
        name: T('page.location'),
      },
      {
        path: AppRouteConst.USER,
        name: T('page.user'),
        children: isCanViewUser ? routesOfProfile : [],
      },
      ...(!isCanViewUser ? routesOfProfile : []),
      {
        path: AppRouteConst.PROFILE.PREFIX,
        name: T('page.profile'),
      },
      {
        path: AppRouteConst.SETTING.LIST,
        name: T('page.setting'),
        children: [
          {
            path: AppRouteConst.SETTING.SETTING_CAPTURE_POSITION.LIST,
            name: T('page.settingCapturePosition'),
            children: [
              {
                path: AppRouteConst.SETTING.SETTING_CAPTURE_POSITION.CREATE,
                name: T('page.settingCapturePositionCreate'),
              },
              {
                path: AppRouteConst.SETTING.SETTING_CAPTURE_POSITION.EDIT,
                name: T('page.settingCapturePositionEdit'),
              },
            ],
          },
          {
            path: AppRouteConst.SETTING.SETTING_CAPTURE_POSITION_TEMPLATE.LIST,
            name: T('page.settingCapturePosition'),
            children: [
              {
                path: AppRouteConst.SETTING.SETTING_CAPTURE_POSITION_TEMPLATE
                  .CREATE,
                name: T('page.settingCapturePositionCreate'),
              },
              {
                path: AppRouteConst.SETTING.SETTING_CAPTURE_POSITION_TEMPLATE
                  .EDIT,
                name: T('page.settingCapturePositionEdit'),
              },
            ],
          },
          //
          {
            path: AppRouteConst.SETTING.SETTING_UI_TEMPLATE_BOOTH.LIST,
            name: T('page.settingUiTemplateBooth'),
            children: [
              {
                path: AppRouteConst.SETTING.SETTING_UI_TEMPLATE_BOOTH.CREATE,
                name: T('page.settingUiTemplateBoothCreate'),
              },
              {
                path: AppRouteConst.SETTING.SETTING_UI_TEMPLATE_BOOTH.EDIT,
                name: T('page.settingUiTemplateBoothEdit'),
              },
            ],
          },
          //
          {
            path: AppRouteConst.SETTING.SETTING_NEWS.LIST,
            name: T('page.settingNews'),
            children: [
              {
                path: AppRouteConst.SETTING.SETTING_NEWS.CREATE,
                name: T('page.settingNewsCreate'),
              },
              {
                path: AppRouteConst.SETTING.SETTING_NEWS.EDIT,
                name: T('page.settingNewsEdit'),
              },
            ],
          },
          //
          {
            path: AppRouteConst.SETTING.SETTING_FAQ.LIST,
            name: T('page.settingFAQ'),
            children: [
              {
                path: AppRouteConst.SETTING.SETTING_FAQ.CREATE,
                name: T('page.settingFAQCreate'),
              },
              {
                path: AppRouteConst.SETTING.SETTING_FAQ.EDIT,
                name: T('page.settingFAQEdit'),
              },
            ],
          },
          //
          {
            path: AppRouteConst.SETTING.SETTING_BOOTH_VERSION.LIST,
            name: T('page.bootVersionBackground'),
            children: [
              {
                path: AppRouteConst.SETTING.SETTING_BOOTH_VERSION.CREATE,
                name: T('page.createBoothVersion'),
              },
              {
                path: AppRouteConst.SETTING.SETTING_BOOTH_VERSION.EDIT,
                name: T('page.editBoothVersion'),
              },
            ],
          },

          // boothbackground
          {
            path: AppRouteConst.SETTING.SETTING_BOOTH_BACKGROUND.LIST,
            name: T('page.boothBackground'),
            children: [
              {
                path: AppRouteConst.SETTING.SETTING_BOOTH_BACKGROUND.CREATE,
                name: T('page.createBoothBackground'),
              },
              {
                path: AppRouteConst.SETTING.SETTING_BOOTH_BACKGROUND.EDIT,
                name: T('page.editBoothBackground'),
              },
              {
                path: AppRouteConst.SETTING.SETTING_BOOTH_BACKGROUND_CATEGORY,
                name: T('page.boothBackgroundCategory'),
              },
              {
                path: AppRouteConst.SETTING.SETTING_BOOTH_BACKGROUND_TEMPLATE
                  .LIST,
                name: T('page.boothBackgroundList'),
                children: [
                  {
                    path: AppRouteConst.SETTING
                      .SETTING_BOOTH_BACKGROUND_TEMPLATE.CREATE,
                    name: T('page.createBoothBackgroundList'),
                  },
                  {
                    path: AppRouteConst.SETTING
                      .SETTING_BOOTH_BACKGROUND_TEMPLATE.EDIT,
                    name: T('page.editBoothBackgroundList'),
                  },
                ],
              },
            ],
          },

          // Photo filter
          {
            path: AppRouteConst.SETTING.SETTING_PHOTO_FILTER.LIST,
            name: T('page.photoFilter'),
            children: [
              {
                path: AppRouteConst.SETTING.SETTING_PHOTO_FILTER.CREATE,
                name: T('page.createPhotoFilter'),
              },
              {
                path: AppRouteConst.SETTING.SETTING_PHOTO_FILTER.EDIT,
                name: T('page.editPhotoFilter'),
              },
              {
                path: AppRouteConst.SETTING.SETTING_PHOTO_FILTER_TEMPLATE.LIST,
                name: T('page.photoFilterList'),
                children: [
                  {
                    path: AppRouteConst.SETTING.SETTING_PHOTO_FILTER_TEMPLATE
                      .CREATE,
                    name: T('page.createPhotoFilterList'),
                  },
                  {
                    path: AppRouteConst.SETTING.SETTING_PHOTO_FILTER_TEMPLATE
                      .EDIT,
                    name: T('page.editPhotoFilterList'),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: AppRouteConst.GUIDE,
        name: T('page.guide'),
      },
      {
        path: AppRouteConst.PAGE_403,
        name: T('notAllowView'),
      },
    ];
  }, [T, isCanViewUser]);

  const { parentRoutes, documentTitle } = useMemo(() => {
    let title = '';
    let isFounded = false;
    let parentRoutes: any = [];

    function findMatchingRoutePath(data: any, parents: any) {
      if (!size(data) || isFounded) return;

      forEach(data, (d) => {
        if (isMatchPath(d?.path)) {
          title = d?.name;
          isFounded = true;
          parentRoutes = parents;
          return false;
        }
        findMatchingRoutePath(d?.children, d);
      });
    }

    findMatchingRoutePath(routes, []);

    return { parentRoutes, documentTitle: title ?? '' };
  }, [isMatchPath, routes]);

  return (
    <>
      <Helmet>
        <title>{documentTitle} - FunStudio</title>
      </Helmet>
      {parentRoutes?.path && (
        <>
          <Link to={parentRoutes?.path}>{parentRoutes?.name}</Link>
          &nbsp;&nbsp;{' > '}&nbsp;&nbsp;
        </>
      )}
      {documentTitle}
    </>
  );
};

export default HeaderBreadcrumb;
