import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { PhotoFilterTemplateStateModel } from 'models/setting/photo-filter/photo-filter-template.model';
import {
  activePhotoFilterTemplateAction,
  createPhotoFilterTemplateAction,
  deletePhotoFilterTemplateAction,
  getPhotoFilterTemplateAction,
  inactivePhotoFilterTemplateAction,
  listPhotoFilterTemplateAction,
  updatePhotoFilterTemplateAction,
} from './photo-filter-template.action';

const initialState: PhotoFilterTemplateStateModel = {
  photoFilterTemplateListData: null,
  photoFilterTemplateDetail: null,
  loadingList: false,
  triggerSavePhotoFilterTemplate: null,
};

export const photoFilterTemplateSlice = createSlice({
  name: 'photoFilterTemplate',
  initialState,
  reducers: {
    setPhotoFilterTemplateStateAction: (
      state,
      action: PayloadAction<Partial<PhotoFilterTemplateStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as PhotoFilterTemplateStateModel),
    resetPhotoFilterTemplateStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPhotoFilterTemplateAction.pending, (state) => ({
        ...state,
        photoFilterTemplateDetail: null,
      }))
      .addCase(
        getPhotoFilterTemplateAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          photoFilterTemplateDetail: payload?.data || null,
        }),
      )
      .addCase(getPhotoFilterTemplateAction.rejected, (state) => ({
        ...state,
        photoFilterTemplateDetail: null,
      }))
      .addCase(listPhotoFilterTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(
        listPhotoFilterTemplateAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          loadingList: false,
          photoFilterTemplateListData: {
            items: payload?.data?.items || [],
            totalItems: payload?.data?.totalItems || 0,
            totalPages: payload?.data?.totalPages || 0,
          },
        }),
      )
      .addCase(listPhotoFilterTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createPhotoFilterTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSavePhotoFilterTemplate: { id: uuidv4() },
      }))
      .addCase(updatePhotoFilterTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSavePhotoFilterTemplate: { id: uuidv4() },
      }))

      .addCase(deletePhotoFilterTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deletePhotoFilterTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSavePhotoFilterTemplate: { id: uuidv4() },
      }))
      .addCase(deletePhotoFilterTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activePhotoFilterTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSavePhotoFilterTemplate: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactivePhotoFilterTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSavePhotoFilterTemplate: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const {
  setPhotoFilterTemplateStateAction,
  resetPhotoFilterTemplateStateAction,
} = photoFilterTemplateSlice.actions;

export default photoFilterTemplateSlice.reducer;
